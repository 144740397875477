import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PDFExport } from "@progress/kendo-react-pdf";
import styled from "@emotion/styled";
import Colors from "assets/Style/Colors";
import { handleExportWithComponent } from "utils";
import VisaServices from "services/Visa";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "components/NewToaster";
import moment from "moment";
import { Images } from "assets";

// Header and Footer Styles
const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: "#2c5b8f",
  textAlign: "center",
}));

function VisaInvoice() {
  const contentRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const [detail, setDetail] = useState();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalTax, setTotalTax] = useState(0);

  const calculateTotalTax = (candidates) => {
    return candidates.reduce((totalTax, candidate) => {
      const visaCharges = parseFloat(candidate.visa_charges);
      const tax = visaCharges * 0.05;
      return totalTax + tax;
    }, 0);
  };

  const getVisaDetail = async () => {
    try {
      const { data } = await VisaServices.getVisaDetail({ visa_id: id });
      setDetail(data?.details);
      setCandidates(data?.details?.candidates);
      const totalTax = calculateTotalTax(data?.details?.candidates);
      setTotalTax(totalTax);
    } catch (error) {
      showErrorToast(error);
    }
  };

  useEffect(() => {
    if (id) getVisaDetail();
  }, [id]);

  const chunkRows = (candidates, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < candidates.length; i += chunkSize) {
      chunks.push(candidates.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunks = chunkRows(candidates, 8);

  const pageTemplate = ({ pageNum, totalPages }) => {
    return (
      <>
        {/* Header */}
        <Box
          style={{
            position: "absolute",
            top: "20px",
            left: "0",
            right: "0",
            textAlign: "center",

            paddingLeft: "10px",
          }}
        >
          <Box
            style={{
              alignItems: "center",
            }}
          >
            <Box>
              <img
                style={{ width: "300px", height: "20px" }}
                src={Images.pdfLogo}
              />
              <Box
                style={{
                  color: "#155368",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span style={{ display: "block" }}>MABDE TRADING L.L.C</span>
                </Typography>
                <Typography
                  style={{
                    color: "#155368",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginLeft: "30px",
                  }}
                >
                  <span style={{ display: "block" }}>
                    TEL: 04-3400000, FAX: 04-3488448
                  </span>
                </Typography>
                <Typography
                  style={{
                    color: "#155368",
                    fontWeight: "bold",

                    textAlign: "center",
                  }}
                >
                  <span style={{ display: "block" }}>
                    P.O.BOX 81, DUBAI, UAEasdasasd
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Bill To Section */}
          <Box mb={2}>
            <Typography
              style={{
                border: "2px solid black",
                fontWeight: "bold",
                width: "100px",
                textAlign: "center",
              }}
            >
              Bill To:
            </Typography>
            <Typography
              style={{
                fontWeight: "bold !important",
                marginTop: "24px !important",
                textAlign: "left !important",
              }}
            >
              {detail?.customer?.name}
            </Typography>
          </Box>

          {/* Address and Invoice Details */}

          <Box
            mb={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ textAlign: "left !important" }}>
              <Typography>{detail?.customer?.address}</Typography>
              <Typography style={{ mt: 1 }}>
                Vat number:{detail?.customer?.vat_no}
              </Typography>
            </Box>
            <Box style={{ textAlign: "left !important" }}>
              <Typography style={{ fontWeight: "bold !important" }}>
                Tax Invoice Number: MT/FFMS/V24070
              </Typography>
              <Typography style={{ mt: 1 }}>
                Vat Reg. No. 100511270900003
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold !important",
                  marginTop: "16px !important",
                }}
              >
                Date: {moment(detail?.created_at).format("MM-DD-YYYY")}
              </Typography>
            </Box>
          </Box>

          <Box className="pdf-center" style={{textAlign:"center"}} mb={4}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textDecoration: "underline",
              letterSpacing: "11px",
              textAlign:"center",
              marginLeft:"50px !important"

            }}
          >
           {detail?.payment_status && detail?.payment_status.toLowerCase() == 'paid'   ? "TAX INVOICE" : "TAX PERFORMA INVOICE"}
          </Typography>
        </Box>
        </Box>

        {/* Footer */}
        <Box
          style={{
            position: "absolute",
            bottom: "20px",
            left: "0",
            right: "0",
            textAlign: "center",
            paddingLeft: "30px",
          }}
        >
          <Box
            id="pdf-padding"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "40px",
            }}
          >
            <Box style={{ textAlign: "left !important" }}>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{ display: "inline-block", width: "120px" }}
              >
                Account #:
              </Typography>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{
                  display: "inline-block",
                  fontWeight: "bold !important",
                }}
              >
                0332676739001
              </Typography>
            </Box>
            <Box style={{ textAlign: "left !important" }}>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{ display: "inline-block", width: "120px" }}
              >
                Bank details:
              </Typography>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{
                  display: "inline-block",
                  fontWeight: "bold !important",
                }}
              >
                The National Bank of Ras Al Khaimah
              </Typography>
            </Box>
            <Box style={{ textAlign: "left !important" }}>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{ display: "inline-block", width: "120px" }}
              >
                Branch:
              </Typography>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{
                  display: "inline-block",
                  fontWeight: "bold !important",
                }}
              >
                Umm Hurair.
              </Typography>
            </Box>
            <Box style={{ textAlign: "left !important" }}>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{ display: "inline-block", width: "120px" }}
              >
                Iban:
              </Typography>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{
                  display: "inline-block",
                  fontWeight: "bold !important",
                }}
              >
                AE540400000332676739001
              </Typography>
            </Box>
            <Box style={{ textAlign: "left !important" }}>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{ display: "inline-block", width: "120px" }}
              >
                Swift Code:
              </Typography>
              <Typography
                className="pdf_font"
                component={"span"}
                style={{
                  display: "inline-block",
                  fontWeight: "bold !important",
                }}
              >
                NRAKAEAK.
              </Typography>
            </Box>
          </Box>

          {/* Terms and Conditions */}
          <Box style={{ textAlign: "left !important" }}>
            <Typography style={{ color: Colors.danger }}>
              Terms & Conditions:
            </Typography>
            <Typography>Immediate payment.</Typography>
          </Box>

          {/* Signature Section */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>Authorized Signature</Typography>
              <Typography style={{ color: Colors.primary, fontWeight: "bold" }}>
                MABDE TRADING LLC.
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{
                  color: Colors.primary,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                TEL: 04/3440000 FAX: 04/3448488
              </Typography>
              <Typography
                style={{
                  color: Colors.primary,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                P.O.BOX: 51 DUBAI, U.A.E
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box style={{ p: 3 }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: 2, p: 3 }}
      >
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
        {detail?.payment_status && detail?.payment_status.toLowerCase() == 'paid'   ? "Invoice" : "Performa Invoice"}
        </Typography>
        <Button
          style={{
            border: "2px solid rgba(229, 37, 42, 1)",
            padding: "10px 20px",
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "600",
            color: "rgba(229, 37, 42, 1)",
            backgroundColor: "rgba(229, 37, 42, 0.1)",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => handleExportWithComponent(contentRef)}
        >
          Download PDF
        </Button>
      </Box>
      <Box>
        <PDFExport
          ref={contentRef}
          paperSize="A4"
          margin={40}
          fileName="Visa Invoice"
          pageTemplate={pageTemplate}
        >
          <Box id="pdf_data">
            {chunks.map((chunk, index) => (
              <Box key={index} style={{ pageBreakAfter: "always" }}>
                {/* Table */}
                <TableContainer id="pd-mytable">
                  <Table>
                    <TableHead>
                      <TableRow id="table-header">
                        <TableCell
                          id="table-cell"
                          className="pdf-col"
                          style={{ width: "72px !important" }}
                        >
                          Sr No
                        </TableCell>
                        <TableCell id="table-cell">Candidate</TableCell>
                        <TableCell id="table-cell" className="pdf-col">
                          Visa Rate
                        </TableCell>
                        <TableCell id="table-cell" className="pdf-col">
                          Tax
                        </TableCell>
                        <TableCell id="table-cell" className="pdf-col">
                          Total
                        </TableCell>
                        {/* <TableCell id="table-cell">Amount AED</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {chunk.map((row) => (
                        <TableRow id="table-header" key={row.id}>
                          <TableCell id="table-cell">{row.id}</TableCell>
                          <TableCell id="table-cell">
                            {row?.name}-{row?.visa_tenure}-{row?.visa_type}
                          </TableCell>
                          <TableCell id="table-cell">
                            {parseFloat(
                              parseFloat(row?.visa_charges) -
                                parseFloat(row?.visa_charges) * 0.05
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell id="table-cell">
                            {parseFloat(
                              parseFloat(row?.visa_charges) * 0.05
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell id="table-cell">
                            {row?.visa_charges}
                          </TableCell>
                          {/* <TableCell className="table-cell">{row.amount}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </Box>

          <Box id="pdf_data" className="pdf_margin">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow id="table-header">
                    <TableCell id="table-cell" colSpan={4} />
                    <TableCell id="table-cell">Sub Total</TableCell>
                    <TableCell id="table-cell">
                      {" "}
                      {parseFloat(
                        parseFloat(detail?.total_visa_charges) -
                          parseFloat(detail?.total_visa_charges) * 0.05
                      ).toFixed(2)}{" "}
                      AED
                    </TableCell>
                  </TableRow>
                  <TableRow id="table-header">
                    <TableCell id="table-cell" colSpan={4} />
                    <TableCell id="table-cell">Total Tax</TableCell>
                    <TableCell id="table-cell">
                      {parseFloat(totalTax).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow id="table-header">
                    <TableCell id="table-cell" colSpan={4} />
                    <TableCell id="table-cell">Total</TableCell>
                    <TableCell id="table-cell">
                      {parseFloat(detail?.total_visa_charges).toFixed(2)} AED
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </PDFExport>
      </Box>

      <Box className="border-custom" sx={{ p: 2 }}>
        {/* Header */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box>
            <img
              style={{ width: "445px", height: "50px" }}
              src={Images.pdfLogo}
            />

            <Box sx={{ color: "#155368" }}>
              <HeaderTypography>MABDE TRADING L.L.C</HeaderTypography>
              <StyledTypography
                style={{ color: "#155368", fontWeight: "bold" }}
              >
                TEL: 04-3400000, FAX: 04-3488448 <br />
                P.O.BOX 81, DUBAI, UAE
              </StyledTypography>
            </Box>
          </Box>
        </Box>

        {/* Bill To Section */}
        <Box mb={4}>
          <Typography
            sx={{
              border: "2px solid black",
              fontWeight: "bold",
              width: "100px",
              textAlign: "center",
            }}
          >
            Bill To:
          </Typography>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            {detail?.customer?.name}
          </Typography>
        </Box>

        {/* Address and Invoice Details */}
        <Box
          mb={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>{detail?.customer?.address}</Typography>
            <Typography sx={{ mt: 2 }}>
              Vat number:{detail?.customer?.vat_no}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              Tax Invoice Number: MT/FFMS/V24070
            </Typography>
            <Typography sx={{ mt: 2 }}>Vat Reg. No. 100511270900003</Typography>
            <Typography sx={{ fontWeight: "bold", mt: 2 }}>
              Date: {moment(detail?.created_at).format("MM-DD-YYYY")}
            </Typography>
          </Box>
        </Box>

        {/* Tax Invoice Title */}
        <Box style={{textAlign:"center"}} mb={4}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textDecoration: "underline",
              letterSpacing: "11px",
              textAlign:"center"
            }}
          >
           {detail?.payment_status && detail?.payment_status.toLowerCase() == 'paid'  ? "TAX INVOICE" : "TAX PERFORMA INVOICE"}
          </Typography>
        </Box>

        {/* Table of Charges */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell className="table-cell" sx={{ width: "72px" }}>
                  Sr No
                </TableCell>
                <TableCell className="table-cell">Candidate</TableCell>
                <TableCell className="table-cell">Visa Rate</TableCell>
                <TableCell className="table-cell">Tax</TableCell>
                <TableCell className="table-cell">Total</TableCell>
                {/* <TableCell className="table-cell">Amount AED</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates.map((row) => (
                <>
                  <TableRow key={row.id}>
                    <TableCell className="table-cell">{row.id}</TableCell>
                    <TableCell className="table-cell">
                      {row?.name}-{row?.visa_tenure}-{row?.visa_type}
                    </TableCell>
                    <TableCell className="table-cell">
                      {parseFloat(
                        parseFloat(row?.visa_charges)
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell className="table-cell">
                      {parseFloat(parseFloat(row?.visa_charges) * 0.05).toFixed(
                        2
                      )}
                    </TableCell>
                    <TableCell className="table-cell">
                    {parseFloat(parseFloat(row?.visa_charges)  + parseFloat(row?.visa_charges) * 0.05).toFixed(2)}
                    </TableCell>
                    {/* <TableCell className="table-cell">{row.amount}</TableCell> */}
                  </TableRow>
                </>
              ))}

              <TableRow id="table-header">
                <TableCell id="table-cell" colSpan={3} />
                <TableCell id="table-cell">Sub Total</TableCell>
                <TableCell id="table-cell">
                {parseFloat(detail?.total_visa_charges).toFixed(2)} 
                 
                </TableCell>
              </TableRow>
              <TableRow id="table-header">
                <TableCell id="table-cell" colSpan={3} />
                <TableCell id="table-cell">Total Tax</TableCell>
                <TableCell id="table-cell">
                  {parseFloat(totalTax).toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow id="table-header">
                <TableCell id="table-cell" colSpan={3} />
                <TableCell id="table-cell">Total </TableCell>
                <TableCell id="table-cell">
                {" "}
                  {parseFloat(
                    parseFloat(detail?.total_visa_charges) +
                      parseFloat(detail?.total_visa_charges) * 0.05
                  ).toFixed(2)}{" "}
                  AED{" "}
                  
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Footer - Bank Details */}
        <Box
          mt={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 0.1 }}>Account #:</Typography>
            <Typography sx={{ flex: 1, fontWeight: "bold" }}>
              0332676739001.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 0.1 }}>Bank details:</Typography>
            <Typography sx={{ flex: 1, fontWeight: "bold" }}>
              The National Bank of Ras Al Khaimah
            </Typography>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 0.1 }}>Branch:</Typography>
            <Typography sx={{ flex: 1, fontWeight: "bold" }}>
              Umm Hurair.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 0.1 }}>Iban:</Typography>
            <Typography sx={{ flex: 1, fontWeight: "bold" }}>
              AE540400000332676739001
            </Typography>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Typography sx={{ flex: 0.1 }}>Swift Code:</Typography>
            <Typography sx={{ flex: 1, fontWeight: "bold" }}>
              NRAKAEAK.
            </Typography>
          </Box>
        </Box>

        {/* Terms and Conditions */}
        <Box mt={4}>
          <Typography sx={{ color: Colors.danger }}>
            Terms & Conditions:
          </Typography>
          <Typography>Immediate payment.</Typography>
        </Box>

        {/* Signature Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box mt={4}>
            <Typography>Authorized Signature</Typography>
            <Typography sx={{ color: Colors.primary, fontWeight: "bold" }}>
              MABDE TRADING LLC.
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography
              sx={{
                color: Colors.primary,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              TEL: 04/3440000 FAX: 04/3448488
            </Typography>
            <Typography
              sx={{
                color: Colors.primary,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              P.O.BOX: 51 DUBAI, U.A.E
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VisaInvoice;
