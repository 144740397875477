
import {
    Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses, IconButton, CircularProgress, Chip, Grid, InputLabel,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    Tooltip,
    Checkbox,
    InputAdornment,
    Button,
    styled,
} from '@mui/material';
import { PDFExport } from '@progress/kendo-react-pdf'
import { FontFamily, Images } from 'assets'
import Colors from 'assets/Style/Colors'
import { PrimaryButton } from 'components/Buttons'
import { CircleLoading } from 'components/Loaders'
import { showErrorToast, showPromiseToast } from 'components/NewToaster'
import Pagination from 'components/Pagination'
import SelectField from 'components/Select'
import { useAuth } from 'context/UseContext'
import { useCallbackPrompt } from 'hooks/useCallBackPrompt';
import moment from 'moment';

import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import CustomerServices from 'services/Customer'
import InvoiceServices from 'services/Invoicing'
import { Debounce } from 'utils';
// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
    border: 0,

}));

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: 'Public Sans',
        border: '1px solid #EEEEEE',
        padding: '15px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        color: '#434343',
        paddingRight: '50px',
        background: 'transparent',
        fontWeight: 'bold'

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: 'Public Sans',

        textWrap: 'nowrap',
        padding: '5px !important',
        paddingLeft: '15px !important',
        '.MuiBox-root': {
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            justifyContent: 'center',
            '.MuiBox-root': {
                cursor: 'pointer'
            }
        },
        'svg': {
            width: 'auto',
            height: '24px',
        },
        '.MuiTypography-root': {
            textTransform: 'capitalize',
            fontFamily: FontFamily.NunitoRegular,
            textWrap: 'nowrap',
        },
        '.MuiButtonBase-root': {
            padding: '8px',
            width: '28px',
            height: '28px',
        }
    },
}));

const CreateMonthlyInvoice = () => {
    const navigate = useNavigate()
    // const [handleBlockedNavigation] =
    //     useCallbackPrompt(true)
    const { register, handleSubmit, getValues, setValue, control, formState: { errors }, reset } = useForm();
    // *For Customer Queue
    const [customerQueue, setCustomerQueue] = useState([]);
    const { user, userLogout } = useAuth();
    // *For Filters
    const [filters, setFilters] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [candidates, setCandidates] = useState([])
    const [sort, setSort] = useState('asc')

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const tableHead = [{ name: 'Select', key: 'created_at' }, { name: 'Candidate Name', key: 'name' }, { name: 'Customer', key: 'commission_visa' }, { name: 'Total Service Charges', key: 'commission_monthly' }, { name: 'Applicable Service Charges', key: 'commission_monthly' }]
    const [selectedRows, setSelectedRows] = useState([]);

    const handleCheckboxChange = (item, isChecked) => {
        if (isChecked) {
            // Add the item to the selectedRows state
            setSelectedRows([...selectedRows, item]);
        } else {
            // Remove the item from the selectedRows state
            setSelectedRows(selectedRows.filter(row => row?.id !== item?.id));
        }
    };

    const CreateMonthlyInvoice = async (formData) => {

        console.log(selectedRows);
        const transformedData = selectedRows.map(item => ({
            candidate_id: item.id,
            total_amount: item.applicableVisaCharges,
        }));

        console.log(transformedData, 'transformedDatatransformedData');
        const total = selectedRows.reduce((total, item) => total + item.applicableVisaCharges, 0);
        console.log(total);
        // Check if any 'total_amount' is 0
        if (transformedData.some(item => item.total_amount === 0)) {
            showErrorToast('candidate has a total amount of 0');
        }
        else {

            try {

                let obj = {
                    customer_id: selectedCustomer?.id,
                    total_amount: total,
                    due_date: null,
                    tax: parseFloat(total) * 0.05,
                    candidates: transformedData
                };

                console.log(selectedRows, 'obj');

                const promise = InvoiceServices.CreateMonthlyInvoice(obj);

                showPromiseToast(
                    promise,
                    'Saving...',
                    'Added Successfully',
                    'Something Went Wrong'
                );

                const response = await promise;
                if (response?.responseCode === 200) {
                    navigate("/monthly-invoices");
                    getCandidateList()
                }


            } catch (error) {
                console.log(error);
            }
        }
    };
    // *For Get Customer Queue

    const getCustomerQueue = async (page, limit, filter) => {
        // setLoader(true)
        try {

            let params = {
                page: 1,
                limit: 1000,
            }
            params = { ...params }
            const { data } = await CustomerServices.getCustomerQueue(params)
            console.log(user);

            if (user?.user_type == 'C') {
                console.log(customerQueue);
                let currentUser = data?.rows?.find(item => item?.id == user?.customer_id)
                console.log(currentUser);
                setSelectedCustomer(currentUser)
                setValue('customer', currentUser)

            }
            console.log(data?.rows);

            setCustomerQueue(data?.rows)

        } catch (error) {
            showErrorToast(error)
        } finally {
            // setLoader(false)
        }
    }
    const getCandidateList = async (page, limit, filter, id) => {
        try {
            const Page = page || currentPage;
            const Limit = limit || pageLimit;
            const Filter = { ...filters, ...filter };
            setCurrentPage(Page);
            setPageLimit(Limit);
            setFilters(Filter);

            let params = {
                page: Page,
                limit: Limit,
                customer_id: id ? id : selectedCustomer?.id
            };
            params = { ...params, ...Filter };

            const { data } = await InvoiceServices.getCanidateList(params);

            const candidatesWithCharges = data?.rows?.map(item => {
                let additionalDays = 0;
                const customer = item?.customer;

                // Determine additional days based on due_date_policy
                if (customer.due_date_policy?.includes("7")) {
                    additionalDays = 7;
                } else if (customer.due_date_policy?.includes("15")) {
                    additionalDays = 15;
                }
                else if (customer.due_date_policy?.includes("20")) {
                    additionalDays = 20;
                }
                else if (customer.due_date_policy?.includes("25")) {
                    additionalDays = 25;
                }
                else if (customer.due_date_policy?.includes("30")) {
                    additionalDays = 30;
                }
                else if (customer.due_date_policy?.includes("60")) {
                    additionalDays = 60;
                }


                else if (customer.due_date_policy?.includes("90")) {
                    additionalDays = 90;
                }


                // Find target status and calculate target date
                let targetStatus = item?.statuses?.find(status => status?.status === 'Entry Permit' || status?.status === 'Change Status');
                let targetDate = moment(item?.change_status_date).add(additionalDays, "d");
                let previousMonth = moment().subtract(1, "month");
                let monthDifference = previousMonth.diff(targetDate, "months");

                let remainingDays = 0;
                let totalDaysInPrevMonth = previousMonth.endOf("month").format("DD");

                if (monthDifference === 0) {
                    let totalDaysInTarget = targetDate.format("DD");
                    remainingDays = parseInt(totalDaysInPrevMonth) - parseInt(totalDaysInTarget);
                } else if (monthDifference > 0) {
                    remainingDays = parseInt(totalDaysInPrevMonth);
                }
                console.log(remainingDays,'rem');

                // Calculate applicable visa charges
                let totalVisaCharges
                if (parseFloat(item?.salary_total) > 2000) {
                    totalVisaCharges = parseFloat(item?.customer?.monthly_visa_service_above);
                }
                else {
                    totalVisaCharges = parseFloat(item?.customer?.monthly_visa_service_below);
                }

                let perDayVisaCharges = totalVisaCharges / parseInt(totalDaysInPrevMonth);
                let applicableVisaCharges = (remainingDays + 1) * perDayVisaCharges;
console.log(remainingDays + 1,perDayVisaCharges,'rem');

                // Add applicable charges to the item
                return {
                    ...item,
                    applicableVisaCharges
                };
            });
            console.log(candidatesWithCharges);

            setCandidates(candidatesWithCharges);
            setTotalCount(data?.count);

        } catch (error) {
            showErrorToast(error);
        } finally {
            // setLoader(false)
        }
    };

    // *For Handle Filter
    const handleFilter = () => {
        let data = {
            search: getValues('search')
        }
        Debounce(() => getCandidateList(1, '', data));
    }

    const handleSort = (key) => {
        let data = {
            sort_by: key,
            sort_order: sort
        }
        Debounce(() => getCandidateList(1, '', data));
    }

    useEffect(() => {
        getCustomerQueue()
    }, [])

    return (
        <Box sx={{ p: 3 }} component={'form'} onSubmit={handleSubmit(CreateMonthlyInvoice)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >
                    Create Monthly Service Invoice</Typography>
                {selectedRows.length > 0 && <Box sx={{ display: 'flex', gap: '10px' }}>
                    <PrimaryButton
                        bgcolor={Colors.buttonBg}
                        title="Create Invoice"
                        type='submit'

                    // onClick={ ()=> navigate(`/update-customer/${id}`)}


                    />

                </Box>}
            </Box>
            <Grid container mt={5}>
                <Grid item xs={5} >
                    <SelectField
                        size={'small'}
                        label={'Select Customer :'}
                        disabled={user?.user_type == 'C' ? true : false}
                        options={customerQueue}
                        selected={selectedCustomer}
                        onSelect={(value) => {
                            setSelectedCustomer(value)
                            getCandidateList(null, null, null, value?.id)
                            setValue('customer', value)

                        }}
                        error={errors?.customer?.message}
                        register={register("customer", {
                            required: 'Please select customer account.',
                        })}
                    />
                </Grid>

            </Grid>

            <Grid item md={11}>
                {<Box>

                    <Grid container mb={2} >

                    </Grid>



                    {(
                        (
                            <Fragment>
                                <PDFExport landscape={true} paperSize="A4" margin={5} fileName='Import Customers' >

                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            maxHeight: 'calc(100vh - 200px)', mt: 5, backgroundColor: 'transparent', boxShadow: 'none !important', borderRadius: '0px !important'

                                        }}

                                    >
                                        <Table stickyHeader sx={{ minWidth: 500 }}>
                                            <TableHead>

                                                <Row>
                                                    {tableHead.map((cell, index) => (
                                                        <Cell style={{ textAlign: cell?.name == 'Select' ? 'center' : 'left', paddingRight: cell?.name == 'Select' ? '15px' : '20px' }} className="pdf-table"
                                                            key={index}

                                                        >
                                                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                {cell?.name} {cell?.name == 'Date' && <>&nbsp;<span style={{ height: '20px', cursor: 'pointer' }}><Box component={'img'} onClick={() => { setSort(sort == 'asc' ? 'desc' : 'asc'); handleSort(cell?.key) }} src={Images.sortIcon} width={'18px'}></Box></span></>}
                                                            </Box>
                                                        </Cell>
                                                    ))}
                                                </Row>
                                            </TableHead>
                                            <TableBody>
                                                {candidates?.length > 0 ? (
                                                    candidates.map((item, index) => (
                                                        <Row
                                                            key={index}
                                                            sx={{
                                                                border: '1px solid #EEEEEE !important',
                                                            }}
                                                        >
                                                            <Cell style={{ textAlign: 'center', paddingLeft: '0px !important' }} className="pdf-table">
                                                                <Checkbox
                                                                    checked={selectedRows.includes(item)}
                                                                    disabled={item?.applicableVisaCharges <= 0}
                                                                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                                                                />
                                                            </Cell>
                                                            <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                {item?.name}
                                                            </Cell>
                                                            <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                {item?.customer?.name}
                                                            </Cell>
                                                            <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                {parseFloat(item?.salary_total) > 2000 ? item?.customer?.monthly_visa_service_above : item?.customer?.monthly_visa_service_below}
                                                            </Cell>
                                                            <Cell style={{ textAlign: 'left' }} className="pdf-table">
                                                                {parseFloat(item?.applicableVisaCharges).toFixed(2)}
                                                            </Cell>
                                                        </Row>
                                                    ))
                                                ) : (
                                                    <Row>
                                                        <Cell colSpan={tableHead.length + 1} align="center" sx={{ fontWeight: 600 }}>
                                                            No Data Found
                                                        </Cell>
                                                    </Row>
                                                )}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </PDFExport>
                                {/* ========== Pagination ========== */}
                                {candidates?.length > 0 && <Pagination
                                    currentPage={currentPage}
                                    pageSize={pageLimit}
                                    onPageSizeChange={(size) => getCandidateList(1, size.target.value)}
                                    tableCount={candidates?.length}
                                    totalCount={totalCount}
                                    onPageChange={(page) => getCandidateList(page, "")}
                                />}

                            </Fragment>
                        )
                    )}





                </Box>}





            </Grid>
        </Box>
    )
}

export default CreateMonthlyInvoice
